import React from 'react';
import { graphql, Link } from 'gatsby';
import PageWithHeader from '../components/pageWithHeader';
import Img from 'gatsby-image';
import style from './capabilities.module.scss';

const Capability = ({ data }) => {
  const capabilities = data.capabilities.edges.map(e => e.node);

  return (
    <PageWithHeader title="Capabilities" image={data.image.publicURL} >
      <article className={style.article}>
        {capabilities.map((c, i) => (
          <Link data-test-id={`capability-${i}`} className={style.capabilityCard} to={`/capabilities/${c.slug}`}>
            <Img fixed={c.photo.localFile.childImageSharp.fixed} />
            <div className={style.title}>{c.name}</div>
          </Link>
        ))}
      </article>
    </PageWithHeader>

  );
};

export default Capability;

export const query = graphql`
  query CapabilityQuery{
    image: file(name: { eq: "capabilities" }) {
      publicURL
    }
    capabilities: allContentfulCapability(filter: {node_locale: {eq: "en-US"}}, sort: {fields: order, order: ASC}) {
      edges {
        node {
          name
          slug
          node_locale
          photo {
            contentful_id
            localFile {
              childImageSharp {
                fixed(width: 300, height: 200) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`;

